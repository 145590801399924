<template>
  <div>
    <div class="vx-row">
      <div class="vx-col w-full">
        <feather-icon icon="UserIcon" class="mr-2" svgClasses="w-5 h-5"/>
        <span class="leading-none font-medium">Package Departure Details</span>
      </div>
      <div class="vx-col w-full">
        <vs-input
          class="w-full mt-4"
          label="Departure date"
          type="date"
          :danger="validation.departure_date?true:false"
          :danger-text="validation.departure_date?validation.departure_date[0]:''"
          :value="departureForm.departure_date"
          @input="updateDepartureForm({key: 'departure_date', value: $event})"/>
      </div>
      <div class="vx-col w-full">
        <vs-input
          class="w-full mt-4"
          label="Discount percent"
          :danger="validation.discount_percent?true:false"
          :danger-text="validation.discount_percent?validation.discount_percent[0]:''"
          :value="departureForm.discount_percent"
          @input="updateDepartureForm({key: 'discount_percent', value: $event})"/>
      </div>
      <div class="vx-col w-full">
        <vs-input
          class="w-full mt-4"
          label="Offer Title"
          :danger="validation.offer_title?true:false"
          :danger-text="validation.offer_title?validation.offer_title[0]:''"
          :value="departureForm.offer_title"
          @input="updateDepartureForm({key: 'offer_title', value: $event})"/>
      </div>
      <div class="vx-col w-full">
        <vs-checkbox
          class="w-full mt-4"
          :checked="departureForm.status"
          @change="updateDepartureForm({key: 'status', value: $event.target.checked})">
          Status
        </vs-checkbox>
      </div>
    </div>
    <div class="flex flex-wrap items-center justify-end" style="margin-top: 20px">
      <vs-button button="submit" class="ml-auto">Save Changes</vs-button>
    </div>
  </div>
</template>

<script>

import {mapGetters, mapActions} from 'vuex'
import vSelect from "vue-select";

export default {
  components: {
    vSelect
  },

  computed: {
    ...mapGetters({
      departureForm: 'main_package/getDepartureFormObj',
      validation: 'validation/getValidationErrors',
    })
  },
  methods: {
    ...mapActions({
      updateDepartureForm: 'main_package/updateDepartureForm',
    })
  }
}
</script>
