<template>
  <form method="POST" enctype="multipart/form-data" @submit.prevent="submitForm">
    <div class="vx-row mb-6">
      <div class="vx-col w-full md:w-3/3">
        <div class="vx-card p-6 mb-6">
          <DepartureForm></DepartureForm>

        </div>
      </div>
    </div>
  </form>
</template>

<script>
import DepartureForm from "./common/DepartureForm";
import {mapActions, mapGetters} from "vuex";
import loaderMixin from "@/mixins/loaderMixin";

export default {
  components: {
    DepartureForm,
  },
  mixins: [loaderMixin],
  created() {
    this.clearDepartureForm();
    this.clearValidationErrors();
  },
  computed: {
    ...mapGetters({
      departureForm: 'main_package/getDepartureFormObj',
    }),
    getPackageId() {
      return this.$route.params.packageId;
    },
  },
  methods: {
    ...mapActions({
      createPackageDeparture: 'main_package/createPackageDeparture',
      clearDepartureForm: 'main_package/clearDepartureForm',
      clearValidationErrors: 'validation/clearValidationErrors',
    }),
    submitForm() {
      this.openLoading();
      this.createPackageDeparture({packageId: this.getPackageId, data: this.departureForm})
        .then(response => {
          if (response.status === 201) {
            this.closeLoading();
            this.$vs.notify({
              title: 'Departure Created',
              text: 'Departure created successfully.',
              color: 'success',
              position: 'top-right'
            });
            this.$router.push("/packages/" + this.getPackageId + "/departures");
          }
        }).catch(() => this.closeLoading());
    },
  },
}
</script>
